<template>
  <div class="home">
    <el-container  v-if="loggedIn">
      <el-header><div class="header">Expsol Web Sites</div></el-header>
      <el-main>
    <el-table :data="sites" stripe style="width: 100%">
      <el-table-column prop="Sitename" label="Site" width="180" />
      <el-table-column label="Dev" width="180">
        <template #default="scope">
        <el-link :href="'https://work.expsol.co.za/' + scope.row.Devsite" target="_blank">Load Dev</el-link>
        </template>
      </el-table-column>
      <el-table-column label="Life Site">
        <template #default="scope">
          <el-link :href="scope.row.Prodsite" target="_blank">Load Prod</el-link>
        </template>
      </el-table-column>
    </el-table>
      </el-main>
    </el-container>
  </div>
</template>


<script src="./Home.js">
</script>

<style scoped>
  .el-container {
    padding-top: 30px;
  }
  .header {
    font-weight: bold;
    font-size: 1.5em;
  }

</style>
