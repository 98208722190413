import utils from "../../shared/Utils";

export default {
    namespaced: true,
    state: {
        sites: [],
    },

    mutations: {
        SET_ALL_SITES(state, sites) {
            state.sites = sites;
        },
        ADD_SITE(state, site) {
            state.sites.push(site);
        },
        DELETE_SITE(state, id) {
            state.sites = state.sites.filter((site) => site.ID != id);
        },
        UPDATE_SITE(state, {siteId, site}) {
            const oldSite = state.sites.find((site) => site.ID == siteId);
            oldSite.Lastupload = site.Lastupload;
            oldSite.ID = site.ID;
            oldSite.Sitename = site.Sitename;
            oldSite.Devsite = site.Devsite;
            oldSite.Prodsite = site.Prodsite;
            oldSite.Prodlocation = site.Prodlocation;

            oldSite.Prodtype = site.Prodtype;
            oldSite.Dockerport = site.Dockerport;
            oldSite.Host = site.Host;
            oldSite.Wordpresspassword = site.Wordpresspassword;
            oldSite.Username = site.Username;
        },
    },
    actions: {
        async addSite(context, {site} ) {
            if (site.Wordpresspassword.trim() === "") {
                site.Wordpresspassword = " ";
            }
            // "http://localhost:3000/api/websites",
            fetch(utils.getURL() + "/api/websites", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: context.rootGetters["auth/getTokenHeader"],
                },
                body: JSON.stringify(site),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.body);
                    }
                    return response.json();
                })
                .then((data) => {
                    context.commit("ADD_SITE", data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async updateSite(context, {siteId, updatedSite}) {
            console.log(updatedSite);
            if (updatedSite.Wordpresspassword.trim() === "") {
                updatedSite.Wordpresspassword = " ";
            }
            // "http://localhost:3000/api/websites/"
            fetch(utils.getURL() + "/api/websites/" + siteId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: context.rootGetters["auth/getTokenHeader"],
                },
                body: JSON.stringify(updatedSite),
            })

                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else throw Error(response.body);
                })
                .then((data) => {
                    context.commit("UPDATE_SITE", {siteId: siteId, site: data});
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async deleteSite(context, {site}) {
            fetch(utils.getURL() + "/api/websites/" + site.ID, {
                method: "DELETE",
                headers: {
                    Authorization: context.rootGetters["auth/getTokenHeader"],
                },
            })
                .then((response) => {
                    if (response.ok) {
                        context.commit("DELETE_SITE", site.ID);
                        return;
                    }
                    throw Error(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async getAllWebsites(context) {
            fetch(utils.getURL() + "/api/websites", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: context.rootGetters["auth/getTokenHeader"],
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw Error(response.body);
                    }
                })
                .then((data) => {
                    context.commit("SET_ALL_SITES", data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async uploadSite(context, {siteId}) {
            console.log("UPLOADING " + siteId);
            fetch(utils.getURL() + "/api/websites/upload/" + siteId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: context.rootGetters["auth/getTokenHeader"],
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else throw Error(response.body);
                })
                .then((data) => {
                    context.commit("UPDATE_SITE", {siteId: siteId, site: data});
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    getters: {
        allSites(state) {
            return state.sites;
        }
    },
};
