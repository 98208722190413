<template>
  <the-application-bar></the-application-bar>
  <router-view/>
</template>

<script>
import TheApplicationBar from "./components/UI/TheApplicationBar.vue";

export default {
  components: {TheApplicationBar}
}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

body {
  margin: 0;
}
</style>
