import { mapActions } from "vuex";

export default {
    data() {
        return {
            form: {
                username: "",
                password: "",
            },
            isError: false,
        }
    },
    methods: {
        ...mapActions({ login: "auth/login" }),
        onLogin() {
            this.isError = false;
            this.login({ username: this.form.username, password: this.form.password })
                .then(() => {
                    this.$router.push({ name: "Home" });
                })
                .catch((error) => {
                    this.error = error;
                    this.isError = true;
                });
        },
        onCancel() {
            this.$router.push({ name: "Home" });
        }
    }
};