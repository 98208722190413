<template>
  <el-menu-item :index="item.index" v-if="isVisible">
    <router-link
        :to="item.to"
        v-if="item.to"
      menu-item
    >
      {{ item.name }}
    </router-link>
    <a
        href="#"
        v-if="item.call"
        @click.prevent="item.call"
        menu-item
    >{{ item.name }}</a>
  </el-menu-item>
</template>

<script>
// item: visibleIfLoggedOut, visibleIfLoggedIn, name, to, call

export default {
  props: ['item', 'logged-in'],
  computed: {
    isVisible() {
      return (this.loggedIn && this.item.visibleIfLoggedIn) ||
          (!this.loggedIn && this.item.visibleIfLoggedOut);
    },
  },
  methods: {}
};
</script>

<style>
.el-menu--horizontal > .el-menu-item [menu-item] {
    text-decoration: none;
    color: white;
  }
</style>