import Vuex from 'vuex';
import siteModule from "./websiteStore/index.js";
import authModule from "./authStore/index.js";

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    websites: siteModule,
    auth: authModule,
  }
})
