import {mapActions, mapGetters} from "vuex";

export default {
    name: 'Home',
    methods: {
        ...mapActions({
            getAllWebsites: "websites/getAllWebsites", // map `this.login()` to `this.$store.dispatch('auth/login')`
        }),
        thc() {
            return "table-header";
        }
    },
    computed: {
        ...mapGetters({
            sites: "websites/allSites",
            loggedIn: "auth/isLoggedIn",
        })
    },
    mounted() {
        if (this.loggedIn) {
            this.getAllWebsites().then(() => {
                console.log(this.sites[0]);
            });
        }
    }
}