import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/Login.vue";
import Websites from "../views/Websites.vue";

import store from "@/store/index.js";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    //This is not needed right by now, because the store is  refreshed on page refresh... Will be needed!
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/isLoggedIn"]) {
        next({name: "Home"});
      } else {
        next();
      }
    },
  },
  {
    path: "/websites",
    name: "Websites",
    component: Websites,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
