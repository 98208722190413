import { mapActions, mapGetters } from "vuex";
import { InfoFilled } from '@element-plus/icons-vue';

import { ElMessageBox, ElMessage } from 'element-plus';
//import type { Action } from 'element-plus';

export default {
    name: 'Websites',
    data() {
        return {
            errorTitle: "",
            isError: false,
            InfoFilled: InfoFilled,
            wstypes: [
                { value: 0, description: "HTML"},
                { value: 1, description: "WordPress"},
                { value: 2, description: "Special"},
                { value: 3, description: "FTP"},
            ],
            dialogFormVisible: false,
            currentSite: null,
            form: {
                Sitename: "",
                Devsite: "",
                Prodsite: "",
            },
        };
    },
    methods: {
        ...mapActions({
            getAllWebsites: "websites/getAllWebsites", // map `this.login()` to `this.$store.dispatch('auth/login')`
            uploadSite: "websites/uploadSite",
            updateSite: "websites/updateSite",
            addSite: "websites/addSite",
            deleteSite: "websites/deleteSite",
        }),
        checkInput(value,title) {
            if (value === "") {
                this.errorTitle = title;
                this.isError = true;
                return true;
            }
            return false;
        },
        update() {
            this.form.Sitename = this.form.Sitename.trim();
            this.form.Devsite = this.form.Devsite.trim();
            this.form.Prodsite = this.form.Prodsite.trim();
            this.form.Prodlocation = this.form.Prodlocation.trim();
            this.form.Host = this.form.Host.trim();
            this.form.Username = this.form.Username.trim();
            this.form.Wordpresspassword = this.form.Wordpresspassword.trim();

            if (this.checkInput(this.form.Sitename,"Site Name cannot be blank!")) { return; }
            if (this.checkInput(this.form.Devsite,"Dev Site cannot be blank!")) { return; }
            if (this.checkInput(this.form.Prodsite,"Prod Site cannot be blank!")) { return; }
            if (this.checkInput(this.form.Prodlocation,"Prod Location cannot be blank!")) { return; }

            if (this.currentSite !== null) {
                this.updateSite({siteId: this.currentSite.ID, updatedSite: this.form}).then(() => {
                    this.$forceUpdate();
                    this.dialogFormVisible = false;
                });
            } else {
                this.addSite({site: this.form}).then(() => {
                    this.$forceUpdate();
                    this.dialogFormVisible = false;
                });
            }
        },
        upload(site) {
            this.uploadSite({siteId: site.ID}).then(() => {
                ElMessageBox.alert('Uploaded', 'Result', {
                    confirmButtonText: 'OK',
                    callback: (action) => {
                        ElMessage({
                            type: 'info',
                            message: `action: ${action}`,
                        })
                    },
                });

            });
        },
        edit(site) {
            this.form = {};
            this.form.ID = site.ID;
            this.form.Sitename = site.Sitename;
            this.form.Devsite = site.Devsite;
            this.form.Prodsite = site.Prodsite;
            this.form.Prodlocation = site.Prodlocation;

            this.form.Prodtype = site.Prodtype;
            this.form.Dockerport = site.Dockerport;
            this.form.Host = site.Host;
            this.form.Wordpresspassword = site.Wordpresspassword;
            this.form.Username = site.Username;

            this.currentSite = site;
            this.dialogFormVisible = true;
        },
        addNew() {
            this.form = {};
            this.form.Sitename = "";
            this.form.Devsite = "";
            this.form.Prodsite = "";
            this.form.Prodlocation = "";

            this.form.Prodtype = 0;
            this.form.Dockerport = 0;
            this.form.Host = "";
            this.form.Wordpresspassword = "";
            this.form.Username = "";

                this.currentSite = null;
            this.dialogFormVisible = true;
        },
        deleteit(site) {
            this.deleteSite({site: site}).then(() => {
                this.$forceUpdate();
            });
        },
        fixTime(tmp) {
            let dt = tmp.substring(0,10);
            let tm = tmp.substring(11,19);
            return dt + ' ' + tm;
        },
        getType(site) {
            console.log(site);
            if (site === null || site === undefined) {
                return '';
            }
            return this.wstypes[site.Prodtype].description;
        }
    },
    computed: {
        ...mapGetters({
            sites: "websites/allSites",
            loggedIn: "auth/isLoggedIn",
        }),
        hasLogin() {
            return this.form.Prodtype === 1 || this.form.Prodtype === 3;
        },
        hasDocker() {
            return this.form.Prodtype <= 1;
        }

    },
    mounted() {
        this.getAllWebsites().then(() => {
            //console.log(this.sites[0]);
        });
    },
}