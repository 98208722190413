import { ref } from 'vue';
import NavLink from './NavLink.vue';
import { mapActions, mapGetters } from "vuex";

export default {
    components: { NavLink },
    data() {
        return {
            activeIndex: ref("1")
        };
    },
    methods: {
        ...mapActions({
            login: "auth/login", // map `this.login()` to `this.$store.dispatch('auth/login')`
            logout: "auth/logout",
        }),
        logoutButtonClicked() {
            this.logout().then(() => {
                this.$router.push({ name: "Login" });
            });
        },
        handleSelect() {

        }
    },
    computed: {
        ...mapGetters({
            loggedIn: "auth/isLoggedIn",
        }),
        links() {
            return [
                {
                    index: "1",
                    visibleIfLoggedOut: true,
                    visibleIfLoggedIn: true,
                    name: "Home",
                    to: { name: "Home" },
                },
                {
                    index: "2",
                    visibleIfLoggedOut: false,
                    visibleIfLoggedIn: true,
                    name: "Web Sites",
                    to: { name: "Websites" },
                },
                {
                    index: "3",
                    visibleIfLoggedOut: true,
                    visibleIfLoggedIn: false,
                    name: "Login",
                    to: { name: "Login"},
                },
                {
                    index: "4",
                    visibleIfLoggedOut: false,
                    visibleIfLoggedIn: true,
                    name: "Logout",
                    call: this.logoutButtonClicked,
                }
            ];
        },
        currentUser() {
            const user = this.$store.getters["auth/currentUser"];
            if (user) {
                return user.username;
            }
            return "";
        },
        activeLinks() {
            return this.links.filter(
                (link) => link.visibleIfLoggedOut || this.loggedIn
            );
        },
    },
};