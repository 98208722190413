<template>
  <el-menu
      :default-active="activeIndex"
      class="el-menu"
      mode="horizontal"
      @select="handleSelect"
  >
    <el-image
        class="eslogo"
        src="img/expsol-logo.png"
        fit="contain"
    ></el-image>
    <span class="eslabel">Expsol Admin</span>
    <nav-link v-for="link in links" :key="link.name" :item="link" :logged-in="loggedIn">

    </nav-link>
  </el-menu>
</template>

<script src="./TheApplicationBar.js">

</script>

<style scoped>
  .el-menu {
    background-color: #545c64;
  }
  .eslogo {
    width: 50px;
    height: 50px;
    background-color: white;
    margin-top: 5px;
    margin-left: 5px;
  }

  .eslabel {
    color: white;
    margin-left: 10px;
    margin-top: 15px;
    font-width: bold;
    font-size: 1.25em;
  }
</style>
