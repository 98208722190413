<template>
  <div class="home">
    <el-container>
      <el-header><div class="header">Expsol Web Sites Admin</div></el-header>
      <el-main>
        <el-button type="primary" @click="addNew()">Add New Site</el-button>
        <el-table :data="sites" stripe style="width: 100%" v-if="loggedIn">
          <el-table-column prop="Sitename" label="Site" width="180" />
          <el-table-column label="Type" width="180">
            <template #default="scope">
              {{ getType(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column label="Last Uploaded" width="180">
            <template #default="scope">
              {{ fixTime(scope.row.Lastupload) }}
            </template>
          </el-table-column>
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button :disabled="scope.row.Prodtype == 1" type="success" @click="upload(scope.row)">Upload</el-button>
              <el-button type="primary" @click="edit(scope.row)">Edit</el-button>
              <el-popconfirm
                  confirm-button-text="Yes"
                  cancel-button-text="No"
                  title="Are you sure to delete this?"
                  @confirm="deleteit(scope.row)"
              >
                <template #reference>
                  <el-button type="danger">Delete</el-button>
                </template>
              </el-popconfirm>

            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>

    <el-dialog v-model="dialogFormVisible" title="Website Info">
      <el-form :model="form">
        <el-form-item label="Type" label-width="140px">
        <el-select v-model="form.Prodtype"  placeholder="Select" >
          <el-option
              v-for="item in wstypes"
              :key="item.value"
              :label="item.description"
              :value="item.value"
          >
          </el-option>
        </el-select>
        </el-form-item>

        <el-form-item label="Site Name" label-width="140px">
          <el-input v-model="form.Sitename" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="Dev Site" label-width="140px">
          <el-input v-model="form.Devsite" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="Prod Site" label-width="140px">
          <el-input v-model="form.Prodsite" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="Prod Location" label-width="140px">
          <el-input v-model="form.Prodlocation" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item v-if="hasLogin" label="Host" label-width="140px">
          <el-input v-model="form.Host" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item v-if="hasLogin" label="Username" label-width="140px">
          <el-input v-model="form.Username" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item v-if="hasLogin" label="Password" label-width="140px">
          <el-input v-model="form.Wordpresspassword" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item v-if="hasDocker" label="Docker Port" label-width="140px">
          <el-input-number v-model="form.Dockerport" :min="0" :max="9999" autocomplete="off"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="update"
        >Confirm</el-button
        >
        <el-alert :title="errorTitle" type="error"  effect="dark" v-show="isError"/>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script src="./Websites.js">
</script>

<style scoped>
.el-container {
  padding-top: 30px;
}
.header {
  font-weight: bold;
  font-size: 1.5em;
}
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
}
.el-input {
  width: 300px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
