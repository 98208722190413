<template>
  <el-card class="box-card">
    <template #header>
      <span>Expsol Admin Login</span>
    </template>
    <el-form ref="formRef" :model="form" label-width="120px">
      <el-form-item label="Username">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item label="Password">
        <el-input v-model="form.password" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onLogin">Login</el-button>
        <el-button @click="onCancel">Cancel</el-button>
      </el-form-item>
    </el-form>
    <el-alert title="Login unsuccessful!" type="error"  effect="dark" v-show="isError"/>
  </el-card>
</template>

<script src="./Login.js">
</script>

<style scoped>
  .box-card {
    width: 480px;
    margin-top: 20px;
    margin-left: 20px;
    background-color: #e6f6ff;
  }
</style>
