
export default {
    getURL() {
        let url = window.location.href;
        let pos = url.substring(8).indexOf(":");
        if (pos < 0) {
            pos = url.substring(8).indexOf("/");
        }
        url = url.substring(0,pos+8);
        if (url.indexOf("expsol.co.za") >= 0) {
            return url;
        }
        return url.substring(0,pos+8) + ":3000";
    }
};